import React from 'react';
import "./Home.css";
import mainlogo from "./hfhlogo.webp";
import d2teamcorp from "./d2teamcorp.webp";
import overwatch from "./overwatch.webp";
import picture4 from "./Picture4.svg";
import picture5 from "./Picture5.svg";
import picture6 from "./Picture6.svg";
import picture7 from "./Picture7.svg";
import picture8 from "./Picture8.svg";

const Home = () => {
  return (
    <>
      <header className="navBar text-center bg-black text-white p-2">
        Hotspot.for.Humanity (HfH)®
      </header>

      <main>
        <section className="row mt-5">
          <div className="col-md-6">
            <article className="mission bg-black text-white p-2">
              <h4 className="text-center">Our Mission</h4>
           
                <li><b>Create:</b> Online and In-person Training Focused on Democratizing Inner and Outer Space Education</li><br></br>
                <li><b>Provide:</b> Micro-Credentials for Workforce Development Initatives</li><br></br>
                <li><b>Inspire:</b> Positive Social Outcomes with Technology</li>
              
            </article>
          </div>
          <div className="col-md-6 text-center">
            <img src={mainlogo} alt="Hightech.Humanity Logo" className="main-img img-fluid" />
          </div>
        </section>

        <section className='bg-black text-white p-2 mt-5'>
          <h2 className="joinBox text-center">Join Our Crowdsourcing Campaign</h2>
        </section>

        <section className="text-center mt-5">
          <div className="row row-cols-1 row-cols-md-2 g-4">
            <div className="col">
              <img src={d2teamcorp} alt="D2 Team Corp Initiative" className="img-fluid" />
              <div className="mt-2">
                <a href="https://www.paypal.com/donate/?hosted_button_id=QN2VXMMCDX9GU" className="btn btn-custom-black"><b>CrowdSource (US)</b></a>
              </div>
            </div>
            <div className="col">
              <img src={overwatch} alt="European Crowdsourcing Campaign" className="img-fluid" />
              <div className="mt-2">
                <a href="https://www.paypal.com/donate/?hosted_button_id=EMUA48U8AC6NS" className="btn btn-custom-black"><b>CrowdSource (EU)</b></a>
              </div>
            </div>
          </div>
        </section>

        <section className="social-media bg-black text-white p-2 mt-5">
  <h3 className="text-center"><b>Social Media Engagement</b></h3>
  <div className="row text-center">
    <div className="col">
      <a href="https://www.github.com" target="_blank" rel="noopener noreferrer">
        <img src={picture4} alt="GitHub Profile" className="img-fluid" />
      </a>
    </div>
    <div className="col">
      <a href="https://www.youtube.com/@hotspotforhumanity" target="_blank" rel="noopener noreferrer">
        <img src={picture5} alt="YouTube Channel" className="img-fluid" />
      </a>
    </div>
    <div className="col">
      <a href="https://www.instagram.com/hotspot.for.humanity" target="_blank" rel="noopener noreferrer">
        <img src={picture6} alt="Instagram Profile" className="img-fluid" />
      </a>
    </div>
    <div className="col">
      <a href="https://www.linkedin.com/company/hotspot-for-humanity/" target="_blank" rel="noopener noreferrer">
        <img src={picture7} alt="LinkedIn Company Page" className="img-fluid" />
      </a>
    </div>
    <div className="col">
      <a href="https://www.tiktok.com/@hotspot.for.humanity" target="_blank" rel="noopener noreferrer">
        <img src={picture8} alt="TikTok Profile" className="img-fluid" />
      </a>
    </div>
  </div>
</section>
<footer className="bg-black text-white text-center p-3 mt-5">
  Copyright © 2024 Hotspot.for.Humanity (HfH)®
</footer>

      </main>
    </>
  );
};

export default Home;
